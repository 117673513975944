<template>
  <div class="contentBox">
    <div class="findbox" v-show="submitType == 4">
      <h4>通过手机找回</h4>

      <hr />
      <steps :currentSteps="currentSteps" @changeCurrentSteps="changeCurrentSteps" />
      <div class="stepView">
        <div v-show="currentSteps == 1">
          <p>请输入您的手机号</p>
          <p>
            <el-form :model="params" :rules="rules4" ref="ruleFormPhone">
              <el-form-item prop="phone">
                <el-input type="text" v-model="params.phone" />
              </el-form-item>
            </el-form>
          </p>
        </div>
        <div v-show="currentSteps == 2">
          <p class="warning-tip">
            <span>为了验证您的账号安全，需要验证手机的有效性</span>
            <span>一条包含有效验证码的短信将发送至手机：{{ params.phone | mobileFilters }}</span>
          </p>
          <p class="send-verifycode-box">
            <el-form :model="params" :rules="rules4" ref="ruleFormVerifyCode">
              <el-form-item prop="verifyCode" style="width: 548px">
                <el-input type="text" placeholder="短信验证码" v-model="params.verifyCode" style="width: 500px" />
                <button class="send-verifycode-btn" @click="beforeGetVerifyCode" :disabled="canSendVerifyCode">
                  {{ disCountNumber == '发送验证码' ? disCountNumber : `${disCountNumber}s之后重新发送` }}
                </button>
              </el-form-item>
            </el-form>
          </p>
        </div>
        <div v-show="currentSteps == 3" class="resset-password">
          <p>请重新设置你的账号密码</p>
          <el-form :model="params" :rules="rules4" ref="ruleFormPassword" label-width="25px">
            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="密码"
                v-model="params.password"
                @keyup.enter.native="loginImmediately"
                icon
                style="width:500px"
              />
            </el-form-item>
            <el-form-item prop="passwordCheck">
              <el-input
                type="password"
                placeholder="确认密码"
                v-model="params.passwordCheck"
                @keyup.enter.native="loginImmediately"
                icon
                style="width:500px"
              />
            </el-form-item>
          </el-form>
        </div>
        <p class="btn-box">
          <button class="btn next-btn" v-if="currentSteps !== 3" @click="nextStep">下一步</button>
          <button class="btn cancel-btn" v-if="currentSteps !== 3" @click="cancelFindPassword">取消</button>
          <button
            class="sure-btn"
            v-if="currentSteps == 3"
            @click="loginImmediately"
            :class="disabled ? 'disabled' : ''"
            :disabled="disabled"
          >
            确定
          </button>
        </p>
      </div>
    </div>
    <sliderImgValidate
      v-if="showSliderImgValidate && params.smallImage && params.bigImage"
      :yHeight="params.yHeight"
      :xwidth.sync="params.xwidth"
      :bigImage="params.bigImage"
      :smallImage="params.smallImage"
      :showSliderImgValidate.sync="showSliderImgValidate"
      @changeImgPosition="changeImgPosition"
      @changeImgPositionEnd="changeImgPositionEnd"
      @resetPicAgain="getSliderImgValidate"
    />
  </div>
</template>

<script>
import md5 from 'blueimp-md5'
import {
  getVerifyCodeApi,
  registerApi,
  loginByPwdApi,
  loginByCodeApi,
  findPwdApi,
  slipPicBigApi,
  slipPicSmallApi,
  checkSlipPicSmallApi,
  getUserInfoApi
} from '@/api/index'
import steps from '@/components/steps'
import sliderImgValidate from '@/components/sliderValidate'
import {mapMutations} from 'vuex'

export default {
  components: {
    steps,
    sliderImgValidate
  },
  props: ['submitType'],
  data() {
    const that = this
    const phone = (rule, value, callback) => {
      let regs = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      setTimeout(() => {
        if (!regs.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }, 1000)
    }
    const password = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length <= 7) {
          callback(new Error('请保证您的密码长度最少8位'))
        }
        if (/^\d+$/g.test(value) || /^[a-zA-Z]+$/g.test(value)) {
          callback(new Error('您的密码强度过低！请使用字母数字组合'))
        }
        if (this.params && this.params.passwordCheck !== '') {
          this.submitType == 3 && this.$refs.ruleForm.validateField('passwordCheck')
          this.submitType == 4 && this.$refs.ruleFormPassword.validateField('passwordCheck')
        }
        callback()
      }
    }
    const passwordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== that.params.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const verifyCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入您收到的手机验证码'))
      } else if (value.length !== 6) {
        callback(new Error('请输入您收到的6位数字的手机验证码'))
      } else {
        callback()
      }
    }
    return {
      disCountNumber: '发送验证码',
      timer: null,
      submitType: 1, // 1密码登录 2验证码登录 3注册新用户 4 找回密码
      currentSteps: 1, // 找回密码的步数
      showSliderImgValidate: false, //是否展示滑块验证
      needResetSlideBar: false, //次数超上限时候需要刷新
      params: {
        phone: '',
        verifyCode: '',
        password: '',
        passwordCheck: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },
      rules1: {
        phone: [{validator: phone, trigger: 'blur'}],
        password: [{validator: password, trigger: 'blur'}]
      },
      rules2: {
        phone: [{validator: phone, trigger: 'blur'}],
        verifyCode: [{validator: verifyCode, trigger: 'blur'}]
      },
      rules3: {
        phone: [{validator: phone, trigger: 'blur'}],
        password: [{validator: password, trigger: 'blur'}],
        passwordCheck: [{validator: passwordCheck, trigger: 'blur'}],
        verifyCode: [{validator: verifyCode, trigger: 'blur'}]
      },
      rules4: {
        phone: [{validator: phone, trigger: 'blur'}],
        password: [{validator: password, trigger: 'blur'}],
        passwordCheck: [{validator: passwordCheck, trigger: 'blur'}],
        verifyCode: [{validator: verifyCode, trigger: 'blur'}]
      }
    }
  },
  mounted() {
    // this.getSliderImgValidate()
  },
  computed: {
    ...mapMutations(['ChangeUserInfo']),
    disabled() {
      let flag
      if (!!this.params.phone && this.params.phone.length == 11) {
        if (this.submitType == 1 && !!this.params.password) {
          return false
        }
        if (this.submitType == 2 && !!this.params.verifyCode && this.params.verifyCode.length == 6) {
          return false
        }
        if (
          (this.submitType == 3 || this.submitType == 4) &&
          !!this.params.password &&
          this.params.password == this.params.passwordCheck &&
          this.params.verifyCode &&
          this.params.verifyCode.length == 6
        ) {
          return false
        }
      }
      return true
    },
    canSendVerifyCode() {
      const phone = this.params.phone
      return phone.length == 11 && !this.timer ? false : true
    },
    biz() {
      // 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      // submitType   1密码登录 2验证码登录 3注册新用户 4 找回密码
      const biz = this.needResetSlideBar ? '0' : this.submitType
      const bizType = {0: '3', 1: '3', 2: '1', 3: '0', 4: '2'}
      return bizType[biz]
    }
  },
  methods: {
    // 获取验证码人机校验
    beforeGetVerifyCode() {
      if (this.params.phone) {
        this.showSliderImgValidate = true
        this.getSliderImgValidate()
      } else {
        this.$dialog.alert('请输入手机号')
      }
    },
    // 获取图片验证划图图片链接
    getSliderImgValidate() {
      const params = {}
      this.showSliderImgValidate = true
      params.phone = this.params.phone
      // biz 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      params.biz = this.biz // 申请类型：1-登录密码次数超限；2-获取验证码
      // slipPicBigApi(params).then(res => {
      //   const params = {
      //     ...this.params,
      //     ...res.data
      //   }
      //   this.$set(this, 'params', params)
      // })
      slipPicSmallApi(params).then(res => {
        const params = {
          ...this.params,
          ...(res?.data || {})
        }
        this.$set(this, 'params', params)
      })
    },
    // 修改滑块的水平坐标最终的值
    changeImgPosition(val) {
      this.params.xwidth = val
    },
    // 验证图片位置是否正确
    changeImgPositionEnd() {
      this.$nextTick(() => {
        const params = {}
        params.biz = this.biz
        params.phone = this.params.phone
        params.xwidth = this.params.xwidth || '0'
        this.showSliderImgValidate = false
        checkSlipPicSmallApi(params)
          .then(res => {
            if (res.msg == 'success') {
              // this.$dialog.alert('<span class="iconfont icon-selection" style="font-size:100px;color:green"></span>', {
              //   btns: ['<span style="color:#000000">验证通过</span>'],
              //   callback: [
              //     () => {
              //       this.params.xwidth = 0
              //       this.submitType !== 1 && this.getVerifyCode() // 登录密码次数超限制时候不需要调取验证码
              //     }
              //   ]
              // })
              this.$message('验证通过')
              this.params.xwidth = 0
              this.submitType !== 1 && this.getVerifyCode() // 登录密码次数超限制时候不需要调取验证码
            } else {
              // this.params.xwidth = 0
              this.getSliderImgValidate()
            }
          })
          .catch(err => {
            // this.params.xwidth = 0
            this.getSliderImgValidate()
          })
          .finally(() => {
            const {yHeight, xwidth, bigImage, smallImage} = this.$options.data().params
            this.params = {...this.params, yHeight, xwidth, bigImage, smallImage}
          })
      })
    },
    // 获取验证码
    getVerifyCode() {
      // biz string: 0 注册  1 验证码登录  2 找回密码
      // submitType 1密码登录 2验证码登录 3注册新用户 4 找回密码
      let biz = this.biz
      const phone = this.params.phone
      getVerifyCodeApi({phone, biz})
        .then(res => {
          this.disCount()
        })
        .catch(err => {
          this.disCount()
        })
    },
    // 倒计时
    disCount() {
      this.disCountNumber = '60'
      this.timer = setInterval(() => {
        if (this.disCountNumber > 0) {
          this.disCountNumber--
        } else {
          this.disCountNumber = '发送验证码'
          this.timer = null
        }
      }, 1000)
      // const timerkey = `${this.phone}-${this.submitType}`
      // this.timer[timerkey] = setInterval(() => {
      //   if (this.disCountNumber > 0) {
      //     this.disCountNumber--
      //   } else {
      //     this.disCountNumber = '发送验证码'
      //     this.timer[timerkey] = null
      //     this.timer.delete(timerkey)
      //     console.log(this.timer)
      //   }
      // }, 1000)
    },

    // 切换修改登录注册找回密码的类型
    changesubmitType(type) {
      Object.assign(this.$data, this.$options.data())
      this.submitType = type
    },
    // 找回密码步数
    changeCurrentSteps(val) {
      this.currentSteps = val
    },

    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values)
        }
      })
    },
    // 取消找回密码
    cancelFindPassword() {
      if (this.$route.query.submitType) {
        this.$router.go(-1)
        return
      }
      this.currentSteps = 1
      // this.submitType = 1
      this.$emit('update:submitType', 1)
      this.resetAllParams()
    },
    resetAllParams() {
      this.submitType = 1
      Object.assign(this.$data, this.$options.data())
    },
    // 登录注册
    loginImmediately() {
      if ([1, 2, 3].includes(this.submitType)) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.login()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs.ruleFormPassword.validate(valid => {
          if (valid) {
            this.login()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    login() {
      const that = this
      const {password, verifyCode, phone, xwidth} = this.params
      findPwdApi({phone, password: md5(password), verifyCode}).then(res => {
        console.log('%c 找回密码....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        if (res.msg == 'success') {
          // this.$message('密码重置成功')
          // this.$confirm('确认关闭？')
          //   .then(_ => {
          //     this.submitType = 1
          //     this.resetAllParams()
          //   })
          //   .catch(_ => {})
          this.$dialog.alert('密码重置成功', {
            btns: ['确认'],
            callback: [
              () => {
                this.submitType = 1
                this.getUserInfo(res.data)
              }
            ]
          })
        } else {
          if (res.data) this.$dialog.toast(res.data || '注册失败，请重试')
        }
      })
    },
    getUserInfo(str) {
      localStorage.setItem('TOKEN', str)

      getUserInfoApi({phone: this.params.phone}).then(res => {
        // console.log('%c 获取用户信息....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        localStorage.setItem('UserInfo', JSON.stringify(res.data))
        this.$store.commit('ChangeUserInfo', res.data)
        this.$router.push('/home')
      })
    },
    nextStep() {
      if (this.currentSteps == 1) {
        this.$refs.ruleFormPhone.validate(valid => {
          if (valid) {
            this.currentSteps += 1
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }

      if (this.currentSteps == 2) {
        this.$refs.ruleFormVerifyCode.validate(valid => {
          if (valid) {
            this.currentSteps += 1
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}

/**
 *
 * 发送手机验证码 需要关联验证码类型和手机号   手机号+时间搓验证动态验证
 *
 * 划图验证层级最高 = 300
 *
 */
</script>
<style lang="scss" scoped>
.contentBox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
}

.loginbox {
  margin: auto;
  width: 560px;
  border-radius: 10px;
  padding: 30px 61px;
  background: #ffffff;
  .top {
    height: 50px;
    line-height: 50px;
    display: flex;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5ad3cd;
    line-height: 33px;
    margin-top: 15px;
    margin-bottom: 35px;
    color: rgba(154, 154, 154, 1);
    span {
      flex: 1;
    }
  }
  .form-box > p {
    height: 54px;
    margin: 30px 0;
  }
  .form-box .warning-text {
    height: 35px;
    line-height: 35%;
    font-size: 16px;
    font-family: PingFang SC;
    display: flex;
    b {
      flex: 1;
    }
  }
}

.findbox {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 45px;

  h4 {
    height: 95px;
    line-height: 95px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
  hr {
    height: 2px;
    border: 1px solid rgba(227, 228, 232, 1);
  }
}
.stepView {
  width: 550px;
  margin: 20px auto;
  margin-top: 100px;
  color: rgba(154, 154, 154, 1);

  & > div > p {
    margin: 25px;
    font-size: 30px;
    font-family: PingFang SC;

    color: rgba(153, 153, 153, 1);
    input {
      width: 100%;
      height: 50px;
      padding-left: 30px;
      font-size: 20px;
      font-family: PingFang SC;
      border: 1px solid #eef0f5;
    }
    input::-webkit-input-placeholder {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(154, 154, 154, 1);
    }
  }
  .resset-password {
    p {
      height: 50px;
    }
  }
  .warning-tip {
    span {
      display: block;
      width: 800px;
      overflow: auto;

      font-size: 22px;
      font-family: PingFang SC;
      // font-weight: bold;
      color: #999999;
    }
  }
  .btn {
    border: none;
    outline: none;
    font-weight: bold;
    border-radius: 5px;
    width: 209px;
    height: 50px;
    font-size: 20px;
    font-family: PingFang SC;
    // font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
  .next-btn {
    background: rgba(56, 194, 188, 1);
    color: rgba(255, 255, 255, 1);
  }
  .cancel-btn {
    color: #ffffff;
    background: rgba(206, 206, 206, 1);
  }
  .send-verifycode-box {
    display: flex;
    position: relative;
    left: 0;
    top: 0;
  }
  .send-verifycode-btn {
    width: 100px;
    height: 40px;
    position: absolute;
    right: -100px;
    top: 0;
    padding: 0 15px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    font-family: PingFang SC;
    color: rgba(56, 194, 188, 1);
    background: #ffffff;
    border: 1px solid rgba(227, 228, 232, 1);
  }
  .btn-box {
    display: flex;
    padding: 25px;
    justify-content: space-between;
  }
}

.sure-btn {
  width: 100%;
  height: 60px;
  border: none;
  outline: none;
  font-family: PingFang SC;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  background: rgba(56, 194, 188, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
}
.sure-btn :hover {
  cursor: pointer !important;
  // color: #5ad3cd !important;
}
.active {
  color: #19c4b2;
  position: relative;
}
.active::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -15px;
  width: 95px;
  height: 3px;
  // border: 1px solid rgba(24, 196, 178, 1);
  background: rgba(24, 196, 178, 1);
  border-radius: 2px;
  transform: translate(-50%, -50%);
  border: 1px solid #19c4b2;
}

.disabled {
  background: #cccccc;
  color: #ffffff;
}

.send-btn {
  display: inline-block;
  padding: 0 30px;
  height: 61px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 20px;
  font-family: PingFang SC;
  color: rgba(95, 213, 200, 1);
  color: #19c4b2;
  border-left: 1px solid #cccccc;
}

a {
  font-size: 8px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #008cff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
