<template>
  <div>
    <div class="top hoverstyle" v-if="submitType != 3">
      <span @click="$emit('update:submitType', 1)" :class="submitType == 1 ? 'active' : ''"> 密码登录 </span>
      <span @click="$emit('update:submitType', 2)" :class="submitType == 2 ? 'active' : ''"> 验证码登录 </span>
    </div>
    <div><slot></slot></div>
    <div>
      <p v-if="submitType != 3" class="warning-text hoverstyle">
        <span @click="$emit('update:submitType', 3)"> 注册新用户 </span> <b></b>
        <span v-if="submitType == 1" @click="$emit('update:submitType', 4)">忘记密码?</span>
      </p>
      <p v-if="submitType == 3" class="warning-text">
        <span>
          注册即表示同意<a class="hoverstyle" @click="$router.push('/quanqingProtocol')">《全倾用户协议》</a>
        </span>
        <b></b>
        <span> 已有新帐号？<a class="hoverstyle" @click="$emit('update:submitType', 1)">去登录</a> </span>
      </p>
    </div>
    <p>
      <button class="sure-btn" @click="$emit('loginImmediately')">
        {{ submitType == 3 ? '注册账号' : '立即登录' }}
      </button>
    </p>
  </div>
</template>

<script>
export default {
  props: ['submitType']
}
</script>

<style scoped lang="scss">
.top {
  height: 50px;
  line-height: 50px;
  display: flex;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5ad3cd;
  line-height: 33px;
  margin-top: 15px;
  margin-bottom: 35px;
  color: rgba(154, 154, 154, 1);
  span {
    flex: 1;
  }
}

.active {
  color: #5ad3cd;
  position: relative;
}
.active::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -5px;
  width: 40px;
  height: 3px;
  background: #5ad3cd;
  border-radius: 2px;
  transform: translate(-50%, -50%);
  border: 1px solid #5ad3cd;
}

.warning-text {
  height: 35px;
  line-height: 35px;
  margin-bottom: 25px;
  font-size: 16px;
  font-family: PingFang SC;
  display: flex;
  b {
    flex: 1;
  }

  a {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #008cff;
    cursor: pointer !important;
  }
  a:hover {
    color: #5ad3cd !important;
  }
}

.sure-btn {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  font-family: PingFang SC;
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  background: rgba(56, 194, 188, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 25px;
}
.sure-btn :hover {
  cursor: pointer !important;
  // color: #5ad3cd !important;
}
</style>
